<template>
  <div class="margin-left-10">
    <div v-if="hasOrderDescription" class="label">PRP Order Description</div>
    <textarea v-if="hasOrderDescription" v-model="prpOrderDescription" name="info" disabled />
    <div class="label">Description</div>
    <textarea
      v-model.lazy="storedInfo"
      name="info"
      :title="lockedForUser ? lockedTitle : ''"
      :disabled="lockedForUser"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'Description',

  emits: ['info'],

  data() {
    return {
      prpTextCategoriesText: '',
      prpComboCategoriesText: '',
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    storedInfo: {
      get() {
        return this.part.info;
      },

      set(value) {
        this.$emit('info', { info: value });
      },
    },

    hasOrderDescription() {
      return this.prpTextCategoriesText || this.prpComboCategoriesText;
    },

    prpOrderDescription() {
      return `PRP Order Description \n${this.prpTextCategoriesText}\n${this.prpComboCategoriesText}`;
    },
  },

  mounted() {
    if (this.part.part_id) {
      this.axiosInstance.get(`api/v1/prp-order-per-part/${this.part.part_id}/`).then(response => {
        this.prpTextCategoriesText = this.convertObjectToText(response.data.prp_text_categories);
        this.prpComboCategoriesText = this.convertObjectToText(response.data.prp_combo_categories);
      });
    }
  },

  methods: {
    convertObjectToText(categories) {
      let result = '';
      for (let key in categories) {
        if (Object.prototype.hasOwnProperty.call(categories, key)) {
          result += `• ${categories[key].label}: ${categories[key].value}\n`;
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  border-radius: 5px;
  border: solid 1px var(--light-color);
  height: 100px;
  margin-top: 5px;
  width: 100%;
}

.label {
  font-size: var(--12px);
  color: var(--spark-lightgrey);
  margin-left: 7px;
}

.margin-left-10 {
  margin-left: 10px;
}
</style>
