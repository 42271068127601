<template>
  <!-- border ' -->
  <div class="h-64 z-50 mb-4 mx-12 pr-20">
    <!-- <div class="bg-color h-8" /> -->
    <div
      v-if="!isExternal"
      class="bg-white h-56 sticky bottom-0 flex items-center border border-solid rounded-8 border-t-gray-200 part-footer"
    >
      <SparkButton v-if="currentRouteName !== 'Input'" small class="m-4 ml-20 h-32" variant="outlined" @click="back()"
        ><div class="text-13"><i class="fas fa-arrow-left pr-4" />Back</div></SparkButton
      >
      <div class="mr-8 ml-auto">
        <SparkButton small class="m-4 h-32 bg-white" @click="goToLibrary()"
          ><div class="text-13">Cancel</div></SparkButton
        >
        <ShareButton v-if="currentRouteName === 'Overview'" class="mr-20 h-32" />
        <SparkButton
          v-if="currentRouteName === 'Input'"
          small
          class="m-4 mr-20 h-32"
          variant="secondary"
          :title="hoverTitle"
          :disabled="nextDisabled"
          @click="next()"
        >
          <div class="text-13">Next<i class="fas fa-arrow-right pl-4" /></div>
        </SparkButton>
      </div>
    </div>
    <div class="bg-color h-16" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '../../SparkComponents/SparkButton.vue';

import ShareButton from '@/components/PartWorkflow/Overview/ShareButton.vue';

export default {
  name: 'PartFooter',

  components: {
    ShareButton,
    SparkButton,
  },

  computed: {
    ...mapState(['part', 'user']),

    currentRouteName() {
      return this.$route.name;
    },

    isExternal() {
      return this.user.is_external;
    },

    nextDisabled() {
      if (this.currentRouteName === 'Input' && this.partIdExisting) {
        return false;
      } else {
        return true;
      }
    },

    hoverTitle() {
      if (this.nextDisabled) {
        if (this.currentRouteName === 'Input') {
          return 'Please upload a Cad file first.';
        } else if (this.currentRouteName === 'Comparison') {
          return 'Please select a main process chain first.';
        }
      }
      return '';
    },

    partIdExisting() {
      return this.part.part_id != 0;
    },

    primaryProcessChainExisting() {
      return (
        this.part?.primary_process_chain_id !== '' &&
        this.part?.primary_process_chain_id !== null &&
        this.part?.primary_process_chain_id !== undefined
      );
    },
  },

  methods: {
    goToLibrary() {
      this.$router.push({ path: '/library/part' });
    },

    next() {
      if (this.currentRouteName === 'Input' && this.partIdExisting) {
        this.$router.push({ name: 'Comparison', params: { part_id: this.part.part_id } });
      } else if (this.currentRouteName === 'Comparison' && this.partIdExisting && this.primaryProcessChainExisting) {
        this.$router.push({
          name: 'Overview',
          params: { part_id: this.part.part_id, process_chain_id: this.part.primary_process_chain_id },
        });
      }
    },

    back() {
      if (this.currentRouteName === 'Comparison') {
        this.$router.push({ name: 'Input', params: { part_id: this.part.part_id } });
      } else if (this.currentRouteName === 'Overview') {
        this.$router.push({ name: 'Comparison', params: { part_id: this.part.part_id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-color {
  background: var(--lighter-color);
}
</style>
