<template>
  <div class="sticky rounded-r-8 bg-white border border-gray-100 pl-12">
    <div v-if="displayUserActions">
      <BarButtons
        class="report-print-exclude button-margin"
        :view-scale-factor="viewScaleFactor"
        :physical-part-scale-z="physicalPartScaleZ"
        :load-model-completed="loadModelCompleted"
        :plate-offset-to-focus-part-in-middle-of-canvas="plateOffsetToFocusPartInMiddleOfCanvas"
        :plot-machine-envelope-when-loading-model-is-finished="plotMachineEnvelopeWhenLoadingModelIsFinished"
        @reset-to-default-view="zoomToExtends($event)"
        @toggle-build-envelope="toggleMachineEnvelope($event)"
        @update-color-in-canvas="rotateAndUpdateFeasibilityChecks($event)"
        @edit-orientation="showEditOrientation = true"
      />
    </div>
    <OrientationOptimizationSetting
      v-if="showEditOrientation"
      @close-orientation-dialog="showEditOrientation = false"
    />
    <div>
      <div id="canvas" ref="canvasCoreDisplay" class="canvas-container pr-8">
        <CanvasGrid
          :zoom="zoom"
          :client-width="clientWidth"
          :client-height="clientHeight"
          :scene-width-in-m-m="sceneWidthInMM"
          :view-scale-factor="viewScaleFactor"
        />

        <div class="picker-box">
          <Renderer ref="renderer" resize alpha>
            <CanvasCamera
              ref="camera"
              :client-width="clientWidth"
              :client-height="clientHeight"
              @scene-width-in-mm-updated="sceneWidthInMM = $event"
            />

            <Scene ref="scene">
              <PointLight :position="{ x: 5000, y: 5000, z: 5000 }" :intensity="0.75" cast-shadow />
              <PointLight :position="{ x: -5000, y: -5000, z: -5000 }" :intensity="0.75" cast-shadow />
              <AmbientLight :intensity="0.2" cast-shadow />
              <Mesh />
            </Scene>
          </Renderer>
        </div>
      </div>
    </div>
    <CanvasInformation class="report-print-exclude" />
  </div>
</template>

<script>
import { Vector3 } from 'three';
import { Renderer, Scene, PointLight, AmbientLight, Mesh } from 'troisjs';
import { mapState, mapMutations } from 'vuex';

import BarButtons from './BarButtons.vue';
import CanvasCamera from './CanvasCamera.vue';
import CanvasGrid from './CanvasGrid.vue';
import CanvasInformation from './CanvasInformation.vue';
import OrientationOptimizationSetting from './OrientationOptimizationSetting.vue';

import { ArcballControls } from '@/assets/js/3d_viewer/ArcballControls.js';
import { DRACOLoader } from '@/assets/js/3d_viewer/DRACOLoader.js';
import { GLTFLoader } from '@/assets/js/3d_viewer/GLTFLoader.js';
import { CoordSystem } from '@/assets/js/3d_viewer/SparkModules/CoordinateSystem.js';
import { MeshColorer, ConvexHullColorer } from '@/assets/js/3d_viewer/SparkModules/MeshColor.js';
import {
  ConvexHullCreator,
  FrontSideMeshCreator,
  BackSideMeshCreator,
} from '@/assets/js/3d_viewer/SparkModules/MeshCreation.js';
import { FeasibilityEvaluater } from '@/assets/js/3d_viewer/SparkModules/MeshFeasibilities.js';
import { FacePickerOnHull } from '@/assets/js/3d_viewer/SparkModules/MeshMouseInteraction.js';
import { MeshRotater, getNormalToPlaneAnglesRad } from '@/assets/js/3d_viewer/SparkModules/MeshRotation.js';
import { NestingsHandler } from '@/assets/js/3d_viewer/SparkModules/NestedMeshesHandler.js';
import { Zoomer } from '@/assets/js/3d_viewer/SparkModules/ZoomToExtends.js';

const origin = !import.meta.env.PROD ? 'http://localhost' : document.location.origin;

export default {
  name: 'TroisCanvas',

  components: {
    Renderer,
    CanvasGrid,
    CanvasCamera,
    Scene,
    PointLight,
    AmbientLight,
    Mesh,
    BarButtons,
    CanvasInformation,
    OrientationOptimizationSetting,
  },

  props: {
    displayUserActions: Boolean,
  },

  emits: ['load-model-completed'],

  data() {
    return {
      activeAttribute: 'geometry',
      currentModel: '',
      loader: {},
      dracoloader: {},
      viewScaleFactor: 1,
      refScene: {},
      refCoordSys: null,
      refMeshRotater: null,
      refMeshInteractor: null,
      refHullColorer: null,
      plateOffsetToFocusPartInMiddleOfCanvas: 0,
      plotMachineEnvelopeWhenLoadingModelIsFinished: false,
      checkOccludedSupports: null,
      fitsMachine: null,
      loadModelCompleted: false,

      clientWidth: 0,
      clientHeight: 0,
      sceneWidthInMM: 0,
      zoom: 0,
      showEditOrientation: false,
    };
  },

  computed: {
    ...mapState(['part', 'investigationDetails']),
    ...mapState('application', ['axiosInstance']),
    ...mapState('canvas', ['canvas', 'reloadCanvas']),

    rotStat() {
      return this.canvas.rot_stat;
    },

    transparencyToggle() {
      return this.canvas.transparency;
    },

    hullToggle() {
      return this.canvas.showHull === true && !this.noActiveProcessChainSelected && this.refMeshColorer !== null;
    },

    highlightedProcessChainUid() {
      return this.investigationDetails.uid;
    },

    activeProcessChain() {
      if (this.highlightedProcessChainUid != '' || this.highlightedProcessChainUid != null) {
        let allChains = this.part.process_chains;
        return allChains[this.highlightedProcessChainUid];
      } else return undefined;
    },

    noActiveProcessChainSelected() {
      return this.activeProcessChain == null;
    },

    activeProcChainFeasibilityIsValid() {
      let isValid;
      if (this.activeProcessChain?.feasibility == undefined) {
        isValid = false;
      } else {
        isValid = Object.keys(this.activeProcessChain?.feasibility).length > 0;
      }
      return isValid;
    },

    physicalPartScale() {
      let scale = [1, 1, 1];
      if (this.noActiveProcessChainSelected) return scale;
      if (this.activeProcessChain.scale == null) {
        return scale;
      } else {
        return this.activeProcessChain.scale;
      }
    },

    physicalPartScaleZ() {
      return this.physicalPartScale[2];
    },

    physicalPlateOffsetProcChain() {
      if (this.activeProcessChain?.plt_ofs !== undefined) {
        return this.activeProcessChain.plt_ofs;
      } else {
        return 0;
      }
    },

    rotDisplayed() {
      let rotJson;
      if (this.noActiveProcessChainSelected) {
        rotJson = { x: 0, y: 0, z: 0, pushToBackend: false };
      } else if (this.rotStat === 'rot_cost' && this.activeProcessChain.rot_x_cost != undefined) {
        rotJson = {
          x: this.activeProcessChain.rot_x_cost,
          y: this.activeProcessChain.rot_y_cost,
          z: this.activeProcessChain.rot_z_cost,
        };
      } else {
        rotJson = this.activeProcessChain.rot_user;
      }
      return rotJson;
    },

    machineEnvelope() {
      let machineEnvelope;

      if (this.activeProcessChain) {
        machineEnvelope = new Vector3(
          this.activeProcessChain.machine_bld_size_x,
          this.activeProcessChain.machine_bld_size_y,
          this.activeProcessChain.machine_bld_size_z
        );
      }
      return machineEnvelope;
    },

    showNesting() {
      return this.canvas.showNesting;
    },
  },

  watch: {
    rotDisplayed: {
      handler() {
        this.applyUserRotAndPushFeasChecksToBackend();
      },

      immediate: true,
      deep: true,
    },

    physicalPlateOffsetProcChain: {
      handler(newVal) {
        this.setPlateOffset(newVal);
      },
    },

    showNesting() {
      this.applyUserRotAndPushFeasChecksToBackend();
    },

    hullToggle(show) {
      this.refHullColorer.showMesh(show);
      this.refMeshColorer.defaultColoring();
    },

    reloadCanvas: {
      handler(newBool) {
        this.canvasReloading(newBool);
      },
    },

    physicalPartScale: {
      handler(newValue, oldValue) {
        if (String(oldValue) != String(newValue)) {
          this.handlePhysicalPartScale(newValue);
        }
      },

      immediate: true,
    },

    activeProcessChain: {
      handler() {
        this.updateActiveAttribute('geometry');
        this.applyUserRotAndPushFeasChecksToBackend();
      },
    },
  },

  mounted() {
    this.loader = new GLTFLoader();
    this.dracoloader = new DRACOLoader(); // Decompressing
    this.loader.setDRACOLoader(this.dracoloader);
    this.$refs.renderer.renderer.setPixelRatio(window.devicePixelRatio); // Resolution

    this.$refs.renderer.renderer.domElement.addEventListener('pointerdown', this.pickFace);
    this.$refs.renderer.renderer.domElement.addEventListener('pointermove', this.colorHoveredFace);

    if (this.part.part_id !== '') {
      // Coming from Library - reload with part
      this.createModelPath();
    }

    this.updateReloadCanvas(false);

    // ThreeJS variables (e.g. this.$refs.camera.camera.zoom) are not watchable directly.
    // Therefor a local (watchable) interim variable needs to be updated regularly to store the threejs variables correctly.
    const checkIntervalInMS = 50;
    this.checkThreejsInterval = setInterval(() => {
      this.zoom = this.$refs.camera.camera.zoom;
      this.clientWidth = this.$refs.canvasCoreDisplay.clientWidth;
      this.clientHeight = this.$refs.canvasCoreDisplay.clientHeight;
    }, checkIntervalInMS);
  },

  beforeUnmount() {
    clearInterval(this.checkThreejsInterval);
    if (this.refCoordSys != null) {
      this.refCoordSys.removeListener();
    }
    this.$refs.renderer.renderer.domElement.removeEventListener('pointerdown', this.pickFace);
    this.$refs.renderer.renderer.domElement.removeEventListener('pointermove', this.colorHoveredFace);

    for (let i = this.$refs.scene.scene.children.length - 1; i >= 0; i--) {
      if (this.$refs.scene.scene.children[i].type === 'Mesh') {
        this.$refs.scene.scene.children[i].geometry.dispose();
        this.$refs.scene.scene.children[i].material.dispose();
        this.$refs.scene.scene.remove(this.$refs.scene.scene.children[i]);
      } else {
        try {
          this.$refs.scene.scene.children[i].dispose();
        } catch (error) {
          // console.log("could not dispose ", this.$refs.scene.scene.children[i]);
        }
      }
    }
    this.$refs.renderer.renderer.dispose();
  },

  methods: {
    ...mapMutations(['updateFeasibilityChecks', 'updateSingleProcessChain']),
    ...mapMutations('canvas', ['updateReloadCanvas']),
    ...mapMutations(['rotUserChangedAllAxes']),

    colorHoveredFace(event) {
      if (this.hullToggle) {
        const face = this.refMeshInteractor.pickFace(event, this.viewScaleFactor)[0];
        this.refHullColorer.facePickColoring(face);
      }
    },

    pickFace(event) {
      if (this.hullToggle) {
        const faceNormal = this.refMeshInteractor.pickFace(event, this.viewScaleFactor)[1];
        if (faceNormal !== null) {
          const rotDeg = getNormalToPlaneAnglesRad(faceNormal);

          const dict = {
            uid: this.highlightedProcessChainUid,
            x: rotDeg[0],
            y: rotDeg[1],
            z: rotDeg[2],
            pushToBackend: true,
          };
          this.rotUserChangedAllAxes(dict);
        }
      }
    },

    toggleMachineEnvelope(event) {
      if (this.refScene.children !== undefined) {
        // always create clean slate
        for (let i = this.refScene.children.length - 1; i >= 0; i--) {
          if (this.refScene.children[i].name === 'build_envelope') this.refScene.remove(this.refScene.children[i]);
        }
      }

      if (!event.deleteBool) {
        this.$refs.scene.scene.add(event.build);
      }
    },

    applyUserRotAndPushFeasChecksToBackend() {
      if (this.rotDisplayed != null && this.refMeshRotater != null) {
        this.rotateAndUpdateFeasibilityChecks();

        if (this.rotDisplayed.pushToBackend == true && !this.noActiveProcessChainSelected) {
          this.saveProcessChain({
            rot_x_user: this.rotDisplayed.x,
            rot_y_user: this.rotDisplayed.y,
            rot_z_user: this.rotDisplayed.z,
            support_chk: this.checkOccludedSupports,
            fits_machine_chk: this.fitsMachine,
          });
        }
      }
    },

    rotateAndUpdateFeasibilityChecks(attribute) {
      this.updateActiveAttribute(attribute);

      this.refMeshRotater.setKardanRotationDeg(this.rotDisplayed.x, this.rotDisplayed.y, this.rotDisplayed.z);
      let [rotVerticesPos, orientedBB] = this.refMeshRotater.getVerticesPositionsOfCurrentRotation();

      if (this.activeProcChainFeasibilityIsValid) {
        let feasibility = this.activeProcessChain.feasibility;
        let machineBBScaled = this.feasEval.prepareMachineBB(this.machineEnvelope, this.physicalPartScale, orientedBB);
        this.feasEval.computeMandatoryFeasibilityMaps(feasibility, rotVerticesPos, machineBBScaled);
        [this.checkOccludedSupports, this.fitsMachine] = this.feasEval.evalFeasibilityChecks(feasibility);

        let feasChecks = {
          uid: this.highlightedProcessChainUid,
          occludedSupports: this.checkOccludedSupports,
          fitsMachine: this.fitsMachine,
        };
        this.updateFeasibilityChecks(feasChecks);
      }
      this.updateColoringOfPart();

      let nestHandler = new NestingsHandler(this.activeProcessChain, this.$refs.scene, this.refMeshRotater);
      nestHandler.addAdditionalNestingMeshesToSceneIfRequired(this.showNesting, this.physicalPlateOffsetProcChain);
    },

    updateActiveAttribute(attribute) {
      if (attribute != undefined) {
        this.activeAttribute = attribute;
      }
    },

    updateColoringOfPart() {
      if (this.activeAttribute == 'geometry' || this.noActiveProcessChainSelected) {
        this.refMeshColorer.defaultColoring(this.transparencyToggle);
      } else {
        try {
          let mapToPlot = this.feasEval.computeFeasibilityMapToPlot(
            this.activeAttribute,
            this.activeProcessChain.feasibility
          );
          this.refMeshColorer.feasColoring(mapToPlot, this.transparencyToggle);
        } catch (error) {
          // a canvas button is selected that the newly selected process chain does not
          // have (e.g. sharp_edges_detection for AM)
          if (error instanceof TypeError) {
            this.updateActiveAttribute('geometry');
            this.updateColoringOfPart();
          }
        }
      }
    },

    handlePhysicalPartScale() {
      this.updateReloadCanvas(true);
    },

    canvasReloading(newBool) {
      if (newBool) {
        // loadModel only if a part id is given and CAD file was not deleted (there still is a basename)

        this.activeAttribute = 'geometry';

        this.clearScene();
        this.loadModelCompleted = false;
        if (this.part.part_id !== '' && this.part.part_vol) {
          this.createModelPath();
          try {
            this.loadModel(this.currentModel);
          } catch (error) {
            // error is e.g. provoked when coming from library to partframe, and the canvas is not yet mounted
            // but canvasReloading() is already executed. There seems to be some kind of race condition as well,
            // as the error is not always happening in this scenario.

            console.log('Reloading...');
            this.updateReloadCanvas(false);
            this.updateReloadCanvas(true);
          }
        }
        this.updateReloadCanvas(false);
      }
    },

    clearScene() {
      if (this.$refs.scene !== undefined) {
        for (let i = this.$refs.scene.scene.children.length - 1; i >= 0; i--) {
          if (this.$refs.scene.scene.children[i].type === 'Mesh') {
            this.$refs.scene.scene.children[i].geometry.dispose();
            this.$refs.scene.scene.children[i].material.dispose();
            this.$refs.scene.scene.remove(this.$refs.scene.scene.children[i]);
          } else if (this.$refs.scene.scene.children[i].type === 'Group') {
            this.$refs.scene.scene.remove(this.$refs.scene.scene.children[i]);
          }
        }
      }
    },

    createModelPath() {
      this.currentModel = origin + '/api/v1/part/' + this.part.part_id + '/visualization-file/';
    },

    loadModel(modelpath) {
      // console.debug('loadModel');
      this.loader.setRequestHeader({
        Authorization: 'Bearer ' + this.$keycloak.token,
      });

      this.loader.load(modelpath, gltf => {
        this.refScene = this.$refs.scene.scene;
        let scene = this.$refs.scene.scene;
        let camera = this.$refs.camera;

        this.checksOnInputScene(gltf);
        this.removeAllMeshesFromScene();

        let frontSideMesh = gltf.scene.children[0].children[0];
        new FrontSideMeshCreator().addMeshToScene(scene, frontSideMesh);
        let backsideMesh = new BackSideMeshCreator().addMeshToScene(scene, frontSideMesh);
        let convexHullMesh = new ConvexHullCreator().addMeshToScene(scene, frontSideMesh);

        this.refMeshRotater = new MeshRotater(scene);
        this.refMeshRotater.fetchMeshes(); // Find 'Frontside' and 'Backside' mesh
        this.refMeshRotater.writeFaceNormalsToUserdata();

        this.refMeshInteractor = new FacePickerOnHull(camera.camera, convexHullMesh, scene);
        this.refHullColorer = new ConvexHullColorer(convexHullMesh);
        this.refHullColorer.showMesh(false); // avoid convex hull to shine through when displaying machine for the first time

        this.feasEval = new FeasibilityEvaluater(frontSideMesh);
        this.refMeshColorer = new MeshColorer(frontSideMesh, backsideMesh);
        this.refMeshColorer.initColorAttribute();
        this.refMeshColorer.defaultColoring();

        this.addArcBallControls();
        this.refCoordSys = new CoordSystem(camera.camera, scene, this.refControls);

        this.sceneInteract = new Zoomer(camera, this.refControls, frontSideMesh, backsideMesh, this.physicalPartScale);
        this.zoomToExtends();

        this.setPlateOffset(this.physicalPlateOffsetProcChain);
        this.plotMachineEnvelopeWhenLoadingModelIsFinished = true;

        this.rotateAndUpdateFeasibilityChecks(); // recolor with the formerly active attribute
      });

      this.$emit('load-model-completed');
      this.loadModelCompleted = true;
    },

    checksOnInputScene(gltf) {
      if (gltf.scene.children.length > 1) {
        console.log('multiple meshes detected');
      }
    },

    removeAllMeshesFromScene() {
      if (this.refScene.children.length > 4) {
        for (let i = this.refScene.children.length - 1; i >= 0; i--) {
          let minusCounter = 0;

          if (this.refScene.children[i - minusCounter].name === 'Frontside') {
            minusCounter += minusCounter;
            this.refScene.remove(this.refScene.children[i - minusCounter]);
            continue;
          }

          if (this.refScene.children[i - minusCounter].name === 'Backside') {
            minusCounter += minusCounter;
            this.refScene.remove(this.refScene.children[i - minusCounter]);
            continue;
          }
        }
      }
    },

    addArcBallControls() {
      // clean slate
      if (this.refControls != null) {
        this.refControls.dispose();
      }

      this.refControls = new ArcballControls(
        this.$refs.camera.camera,
        this.$refs.renderer.renderer.domElement,
        this.$refs.scene.scene
      );
      this.refControls.scaleFactor = 1.1;
      this.refControls.name = 'controls';
      this.refControls.setGizmosVisible(false);
    },

    zoomToExtends() {
      this.sceneInteract.zoomToExtends();
      this.viewScaleFactor = this.sceneInteract.viewScaleFactor;
    },

    setPlateOffset(offsetOfProcessChain) {
      this.plateOffsetToFocusPartInMiddleOfCanvas = this.refMeshRotater.getPlateOffsetInZ() - offsetOfProcessChain;
    },

    saveProcessChain(formData) {
      let process_chain_id = this.activeProcessChain.process_chain_id;
      this.axiosInstance
        .put(`api/v1/process-chain/${process_chain_id}/`, formData)
        .then(response => {
          this.updateSingleProcessChain(response.data);
        })
        .catch(error => {
          console.log('Save Process Chain Error', JSON.stringify(error));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: 'sticky';
  z-index: '4';
}

.canvas-btn-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 3px;

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

@media print {
  .report-print-exclude {
    display: none;
  }
}

.button-margin {
  margin: 5px 0px;
}
</style>
