<template>
  <div class="flex mx-20"><LeadtimeCard /></div>
</template>

<script>
import LeadtimeCard from './LeadtimeCard.vue';

export default {
  name: 'LeadtimePopup',

  components: { LeadtimeCard },
};
</script>
