<template>
  <div v-if="prpParts.length" class="parts mb-32 flex flex-col gap-8">
    <Teleport v-if="isMounted" to="#sub-navigation__action-buttons">
      <PrpPartAction :part-ids="selectedParts" @set-properties="openEditPropertiesModal" />
    </Teleport>
    <FileUploadModal :show="showFileUploadModal" @close="closeFileUploadModal" />
    <div v-if="isSummary" class="flex justify-center mb-8">
      <div class="text-28 font-bold" v-text="'Order Summary'" />
    </div>
    <div class="flex justify-between items-center">
      <div class="font-semibold" v-text="'Parts'" />
      <SparkButton v-if="!isSummary" variant="secondary" @click="openFileUploadModal">
        <div class="flex items-center justify-between gap-8">
          <i class="fas fa-plus text-13" />
          <div class="text-13" v-text="'Add Part'" />
        </div>
      </SparkButton>
    </div>

    <div v-if="!isSummary" class="ml-32">
      <SparkCheckbox
        v-if="prpParts.length > 0"
        v-model="selectAll"
        name="select-all"
        class="text-15 font-bold"
        label="Select All"
        @change="toggleSelectAll"
      >
        <div v-text="'Select All'" />
      </SparkCheckbox>
    </div>
    <div v-for="part in prpParts" :key="part.part_id" :class="{ 'cursor-pointer': !isSummary }">
      <Part
        :is-summary="isSummary"
        :properties="properties[part.part_id]"
        :part="part"
        :current-part-id="currentPartId"
        @selected="updateSelectedParts(part.part_id, $event)"
        @click="event => setPartId(part.part_id, event)"
        @update-properties="setPropertiesForPart(part.part_id, $event)"
        @update:price-per-part="updatePricePerPart(part.part_id, $event)"
        @update:price-per-lot="updatePricePerLot(part.part_id, $event)"
        @price-per-part-updated="updateMarketPricesPerPart(part.part_id, $event)"
      />
      <Teleport v-if="isMounted && showPrpSidebar && currentPartId === part.part_id" to="#prp-side-bar-teleport">
        <PartProperties :part="part" @option-selected="setPropertiesForPart(part.part_id, $event)" />
      </Teleport>
    </div>
    <div class="text-red-500 text-13 font-semibold mt-16">
      <ul>
        <li v-for="(line, index) in prpFootnotes" :key="index" v-text="line" />
      </ul>
    </div>
    <EditPropertiesModal
      :part-ids="selectedParts"
      :part="prpParts[0]"
      :show="showEditPropertiesModal"
      @option-selected="setPropertiesForPart('', $event)"
      @close="closeEditPropertiesModal"
    />
    <OrderSummary v-if="isSummary" :cost-items="orderSummary" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import SparkButton from '../../../components/SparkComponents/SparkButton.vue';
import SparkCheckbox from '../../../components/SparkComponents/SparkCheckbox.vue';

import EditPropertiesModal from './modals/EditPropertiesModal.vue';
import FileUploadModal from './modals/FileUploadModal.vue';
import OrderSummary from './OrderSummary.vue';
import Part from './Part.vue';
import PartProperties from './PartProperties.vue';
import PrpPartAction from './PrpPartAction.vue';

export default {
  name: 'Parts',

  components: {
    Part,
    PartProperties,
    SparkButton,
    FileUploadModal,
    OrderSummary,
    PrpPartAction,
    EditPropertiesModal,
    SparkCheckbox,
  },

  props: {
    isSummary: { type: Boolean },
  },

  data() {
    return {
      currentPartId: '',
      isMounted: false,
      showFileUploadModal: false,
      showEditPropertiesModal: false,
      loading: true,
      properties: {},
      selectedParts: [],
      selectAll: false,
      pricesPerPart: {},
      pricesPerLot: {},
      totalPrice: '',
      updating: false,
      prpFootnotes: '',
      marketPricesPerPart: {},
    };
  },

  computed: {
    ...mapState('prp', ['prpParts', 'prpPartLibraryData', 'selectedPrpParts']),
    ...mapState(['user', 'partLibraryFilters', 'partOptions', 'prpOrderCategories']),
    ...mapState('application', ['showPrpSidebar', 'axiosInstance']),

    totalPricePerPartPart() {
      let totalLowerValue = 0;
      let totalHigherValue = 0;

      for (const key in this.pricesPerPart) {
        if (Object.prototype.hasOwnProperty.call(this.pricesPerPart, key)) {
          totalLowerValue += this.pricesPerLot[key].lowerValue || 0;
          totalHigherValue += this.pricesPerLot[key].higherValue || 0;
        }
      }

      return {
        totalLowerValue: totalLowerValue,
        totalHigherValue: totalHigherValue,
      };
    },

    orderSummary() {
      return [
        {
          name: 'Total Price',
          value: this.totalPrice,
        },
      ];
    },
  },

  watch: {
    totalPricePerPartPart: {
      handler(value) {
        if (value.totalHigherValue > 0) {
          this.totalPrice = `${this.$formatPrice(value.totalLowerValue)} - ${this.$formatPrice(
            value.totalHigherValue
          )}`;
        } else {
          this.totalPrice = this.$formatPrice(value.totalLowerValue);
        }
      },

      deep: true,
      immediate: true,
    },

    selectAll: {
      handler(value) {
        if (value) {
          if (this.showPrpSidebar) {
            this.togglePrpSidebar();
          }
          this.prpParts.forEach(part => {
            this.setSelectedPrpParts({ [part.part_id]: true });
          });
        } else {
          this.setInitialPrpSelectedParts();
        }
      },

      immediate: true,
    },
  },

  mounted() {
    if (this.prpParts.length === 1) {
      this.setPartId(this.prpParts[0].part_id);
    }
    this.isMounted = true;
    this.getParts();
    this.initializeProperties();

    this.pricesPerPart = this.prpParts.reduce((acc, part) => {
      acc[part.part_id] = '';
      return acc;
    }, {});

    this.pricesPerLot = this.prpParts.reduce((acc, part) => {
      acc[part.part_id] = '';
      return acc;
    }, {});
  },

  methods: {
    ...mapMutations('application', ['togglePrpSidebar']),
    ...mapActions('prp', ['fetchPrpPartLibraryData']),
    ...mapMutations('prp', ['setSelectedPrpParts', 'setInitialPrpSelectedParts']),
    ...mapMutations({
      resetPartToDefault: 'resetPart',
    }),

    toggleSelectAll() {
      if (this.selectAll) {
        this.prpParts.forEach(part => {
          this.setSelectedPrpParts({ [part.part_id]: true });
        });
      } else {
        this.setInitialPrpSelectedParts();
      }
    },

    setPartId(partId, event) {
      const isInputField = event?.target?.tagName === 'INPUT';
      this.toggleSelectAll();
      if (this.isSummary || isInputField) return;
      if (!this.showPrpSidebar) {
        this.selectAll = false;
        this.togglePrpSidebar();
      } else if (this.showPrpSidebar && this.currentPartId === partId) {
        this.togglePrpSidebar();
        this.currentPartId = '';
        return;
      }

      setTimeout(() => {
        this.currentPartId = partId;
      }, 100);
    },

    initializeProperties() {
      if (this.prpParts && this.prpParts.length > 0) {
        this.prpParts.forEach(part => {
          this.properties[part.part_id] = {};
        });
      }
    },

    setPropertiesForPart(partId, data) {
      if (data.isMultipleParts) {
        this.selectedParts.forEach(selectedPartId => {
          this.properties[selectedPartId] = data.formData;
        });
        return;
      } else {
        this.properties[partId] = data.formData;
      }
    },

    updatePricePerPart(partId, price) {
      this.updating = true;
      this.pricesPerPart[partId] = price;
      this.$nextTick(() => {
        this.updating = false;
      });
    },

    updatePricePerLot(partId, price) {
      if (this.updating) return;
      this.updating = true;
      this.pricesPerLot[partId] = price;
      this.$nextTick(() => {
        this.updating = false;
      });
    },

    updateMarketPricesPerPart(partId, price) {
      this.marketPricesPerPart[partId] = price;
    },

    closeFileUploadModal() {
      this.showFileUploadModal = false;
    },

    openFileUploadModal() {
      this.resetPartToDefault();
      this.showFileUploadModal = true;
    },

    async getParts() {
      this.loading = true;
      let formData = JSON.parse(JSON.stringify(this.partLibraryFilters));
      formData = { ...formData, ...{ not_checked_out: true } };
      await this.fetchPrpPartLibraryData(formData)
        .then(() => {
          this.loading = false;
          this.initializeProperties();
        })
        .catch(error => {
          console.error(error);
        });
    },

    updateSelectedParts(partId, selected) {
      if (selected) {
        this.selectedParts.push(partId);
      } else {
        this.selectedParts = this.selectedParts.filter(part => part !== partId);
      }
    },

    closeEditPropertiesModal() {
      this.showEditPropertiesModal = false;
    },

    openEditPropertiesModal() {
      if (this.selectedParts.length > 0) {
        this.showEditPropertiesModal = true;
      }
    },
  },
};
</script>
